//初始化socket对象
import config from './socketConfig.js'; //导入配置
import cookie from './cookie.js';
import Vue from 'vue';
import { Message } from 'element-ui';
var socket = {};
socket.install = function (Vue) {
  Vue.prototype.$socket = socket = new WebSocket(config.url);
};
Vue.use(socket);

socket.onopen = sOpen;
socket.onerror = sError;
socket.onmessage = sMessage;
socket.onclose = sClose;

let store;

function sOpen() {
  console.log('connect success and open the wss by ekt');
  socket.send('我已经成功链接上啦');
  store = window.Vue.$store; //成功链接上的时候才可以用store
}
function sMessage(evt) {
  //wss收到消息
  console.log('收到wss的消息');
  let data;
  try {
    let obj = JSON.parse(evt.data);
    if (typeof obj == 'object' && obj) {
      //一般status为‘’就需要更新群成员
      data = obj; //一定要有type 和 from_name属性
      const { from_name } = data;
      let myName = cookie(document).get('name');
      let byMyself = from_name === myName;
      if (data.type === '改群名') {
        const { group_id, group_name, status, oldName } = data;
        let callback;
        if (!byMyself) {
          //不是自己改的
          callback = () => {
            store.state.group.groupStatus = '改群名';
            let chat = store.state.chat.userList.sentMsLis.filter(item => {
              return item.id === group_id;
            })[0];
            if (chat === undefined) {
              //不关自己的事
              return false;
            }
            store.commit('updateGroupStatus', status);
            let index = store.state.chat.userList.sentMsLis.indexOf(chat);
            store.state.chat.userList.sentMsLis[index].name = group_name;
            Message({
              type: 'info',
              message: `${from_name}将"${oldName}"群名修改为"${group_name}"`,
            });
          };
          callback();
        }
      } else if (data.type === '解散群组') {
        //解散群组
        const { group_name, group_id, status } = data;
        let chat = store.state.chat.userList.sentMsLis.filter(item => {
          return item.id === group_id;
        })[0];
        if (chat === undefined) {
          //不关自己的事
          return false;
        }
        let index = store.state.chat.userList.sentMsLis.indexOf(chat);
        let arr = store.state.chat.userList.sentMsLis.concat();
        store.commit('updateGroupStatus', status); //更新群状态
        arr.splice(index, 1);
        store.commit('updateUserList', {
          //触发watch
          userList: arr,
          type: 'sentMsLis',
        });
        delete store.state.chat.msgList.group[group_id];
        Message({
          type: 'info',
          message: `${from_name}将"${group_name}"群聊解散`,
        });
      } else if (data.type === '创建群聊') {
        const { friends, status } = data;
        if (!byMyself) {
          let isHasMe = friends.indexOf(myName) >= 0; //如果邀请好友里面有你则显示消息
          if (isHasMe) {
            store.commit('updateGroupStatus', status); //更新群状态
            store.dispatch('onGetSendMsLis', { isGetHistory: false }); //刷新群组
            Message({
              type: 'info',
              message: `${from_name}邀请你进新群聊`,
            });
          }
        }
      } else if (data.type === '邀请好友进群') {
        const { group_name, from_name, friends, status } = data;
        let isHasMe = friends.indexOf(myName) >= 0; //如果有自己
        if (!byMyself && isHasMe) {
          let friend = '';
          friends.forEach(item => {
            friend += `"${item}"` + '';
          });
          store.commit('updateGroupStatus', status); //更新群状态
          store.dispatch('onGetSendMsLis', { isGetHistory: false }); //刷新群组
          Message({
            type: 'info',
            message: `"${from_name}"邀请好友${friend}进"${group_name}"`,
          });
        }
      } else {
        //发消息
        data.bySelf = data.from === cookie(document).get('uid');
        if (!data.bySelf) {
          //如果不是自己发的话需要保存记录
          if (data.chatType == 'contact') {
            data.chatId = data.from;
          }
          store.commit('updateMsgList', data);
          store.commit('noticeCall', data);
        }
      }
    }
  } catch (e) {
    console.log('提示信息:' + evt.data);
  }
}
function sError(e) {
  console.log('报错啦', e);
}
function sClose() {
  console.log('关了，不好玩');
}

socket.registerCallBack = function (name, fun) {
  //修改注册方法
  console.log(name, this === socket);
  this[name] = fun;
};
socket.registerCallBack('onopen', sOpen);
export default socket;
