const _isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  );
  // if(process.env.VUE_APP_CONFIG === 'qcc'){ // 在这个pcc环境下 一直是移动端页面
  //   flag = true
  // }
  return flag;
};
export default _isMobile;
