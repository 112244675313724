import axios from 'axios';

const http = axios.create({
  //timeout: 8000, /*设置请求超时时间*/
  baseURL: '',
  headers: {
    'content-type': 'application/json',
  },
});

// 添加响应拦截器
http.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export default http;
