// import axios from 'axios';

const Course = {
  namespaced: true,
  state: {
    title: '',
    switchFoot: true,
  },
  mutations: {
    set(state, { type, data }) {
      state[type] = data;
    },
    setkv(state, data) {
      state.kv[data.k] = data.v;
    },
    removekv(state, data) {
      delete state.kv[data];
    },
  },
  actions: {},
};

export default Course;
