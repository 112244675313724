/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 *
 */
let baseUrl, apiUrl, cdnUrl;
let routerMode;
const imgBaseUrl = '';

if (process.env.NODE_ENV === 'production') {
  baseUrl = '';
  apiUrl = '';
  routerMode = 'hash';
  cdnUrl = '';
} else {
  baseUrl = '';
  apiUrl = '/api';
  routerMode = 'hash';
  cdnUrl = '';
}

export { baseUrl, routerMode, imgBaseUrl, apiUrl, cdnUrl };
