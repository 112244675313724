// import axios from 'axios';
import Vue from 'vue';
import {
  getCourseLisUrl,
  uploadFile,
  postAddMainCourse,
  postAddSubCourse,
  postDelCourse,
  postEditCourseName,
  getCourseInfo,
  postSaveCurrPage,
} from '../service/help';

const Course = {
  namespaced: true,
  state: {
    allData: [],
    currSubData: null,
    currPageData: null,
    currPageIndex: 1,
    uploadProgress: 0,
  },
  mutations: {
    toggleUploadProgress(state, data) {
      state.uploadProgress = data;
    },
    resetSubAndPageData(state) {
      state.allData.forEach(v => {
        if (!state.currSubData) return v;
        if (v.id === state.currSubData.parent_id) {
          v.children = v.children.filter(v2 => v2.id !== state.currSubData.id);
        }
        return v;
      });
      console.log(state);
      // state.currSubData = null;
      state.currPageData = null;
      state.currPageIndex = 1;
    },
    changePagesSort(state, data) {
      state.currSubData.pages = data;
      state.currPageData = data[0];
      state.currPageIndex = 1;
    },
    changePageDataAndIndex(state) {
      // 从currSubData.pages中移除当前currPageData
      state.currSubData.pages = state.currSubData.pages.filter(
        (...itemAndIndex) => itemAndIndex[1] !== state.currPageIndex - 1,
      );
      state.currPageIndex -= 1;
      state.currPageData = state.currSubData.pages[state.currPageIndex - 1];
      console.log(state);
    },
    savePageImage(state, data) {
      state.currPageData.media = {
        url: data,
        type: data.match(/(gif|jpg|png|mp4)$/)[0],
      };
    },
    saveCurrPageData(state) {
      state.currPageData = state.currSubData.pages[0] || {
        title: '',
        content: '',
        media: {
          url: '',
          type: '',
        },
      };
      state.currPageIndex = 1;
    },
    saveCurrSubData(state, data) {
      if (data.pages.length === 0) {
        data.pages.push({
          title: '',
          content: '',
          media: {
            url: '',
            type: '',
          },
        });
      }
      state.currSubData = data;
    },
    saveAllCourse(state, data) {
      state.allData = data.map(v => {
        return {
          ...v,
          children:
            v.children.length === 0
              ? [
                  {
                    parent_id: v.id,
                    label: '+添加子项',
                    class: 'add-son',
                  },
                ]
              : [
                  {
                    parent_id: v.id,
                    label: '+添加子项',
                    class: 'add-son',
                  },
                  ...v.children.map(v2 => {
                    return {
                      ...v2,
                      isEdit: false,
                      isDel: false,
                      class: 'son',
                    };
                  }),
                ],
          class: 'big',
          isEdit: false,
          isDel: false,
        };
      });
    },
    togglePageIndex(state, data) {
      state.currPageIndex = data;
    },
    togglePageData(state, data = null) {
      state.currPageData = !data ? state.currSubData.pages[state.currPageIndex - 1] : data;
    },
    addPageIndex(state) {
      state.currPageIndex += 1;
    },
    addCoursePage(state) {
      state.currSubData.pages.push({
        title: '',
        content: '',
        media: {
          url: '',
          type: '',
        },
      });
    },
    saveCurrSelect(state, data) {
      state.currSubData = data;
    },
  },
  actions: {
    async postPageData({ state }) {
      const res = await postSaveCurrPage({
        id: state.currSubData.id,
        label: state.currSubData.label,
        pages: state.currSubData.pages,
      });
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '保存成功!',
        });
      }
      return res;
    },
    async getCourseData({ commit }, data) {
      const res = await getCourseInfo(data);
      if (res.data.success) {
        commit('saveCurrSubData', res.data.data);
        commit('saveCurrPageData');
      }
    },
    async editCourseName(...data) {
      const res = await postEditCourseName(data[1]);
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '保存成功!',
        });
      }
      return res;
    },
    async uploadCover({ commit }, data) {
      const res = await uploadFile(data, e => {
        commit('toggleUploadProgress', Math.floor((e.loaded / e.total) * 100));
      });
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '上传成功!',
        });
        // 找到currSubData中pages的当前page，即要知道当前pageIndex
        commit('savePageImage', res.data.data);
      }
      return res;
    },
    async delCourseData(...data) {
      const res = await postDelCourse(data[1]);
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '删除成功!',
        });
      }
      return res;
    },
    async addSubCourseData(...data) {
      const res = await postAddSubCourse(data[1]);
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '新建成功!',
        });
      }
      return res;
    },
    async addNewCourseData(...data) {
      const res = await postAddMainCourse(data[1]);
      if (res.data.success) {
        Vue.prototype.$message({
          type: 'success',
          message: '新建成功!',
        });
      }
      return res;
    },
    async getAllCourseData({ commit }, data = '') {
      const res = await getCourseLisUrl(data);
      if (res.data.success) {
        commit('saveAllCourse', res.data.data);
      }
    },
  },
};

export default Course;
