import Vue from 'vue';
import Vuex from 'vuex';
import Login from './login';
import Chat from './chat';
import Group from './group';
import Course from './course';
import Mobile from './mobile';
Vue.use(Vuex);

import { postLoginUrl, postWxLogin, postUserinfoWxBind, postUserInfoLis } from '@/service/help';
import cookie from '@/utils/cookie';
export default new Vuex.Store({
  state: {
    path: '/task/progress',
    member: [],
    account: '',
    taskInfo: {
      SubSum: 0,
      UploadSum: 0,
      WorktimeSum: 0,
      charge_id: 0,
      content: '',
      ctime: '',
      dadtask_id: Number,
      etime: '',
      id: 0,
      is_subtask: 1,
      join_id: [],
      level: '',
      sn: '',
      status: 0,
      stime: '',
      title: '',
      type: '',
    },
    dailyInfo: {
      clean_dad: '', //父任务id
      clean_des: '', //描述
      clean_etime: '',
      clean_is_sub: '', //0为否，1为子
      clean_level: '', //紧急度
      clean_status: '0',
      clean_stime: '',
      ctime: '',
      id: '',
      is_deal: '', //0未选
      is_mytask: '',
      status: '', //四大类
      task_id: '-',
      title: '',
      uid: '',
    },
    uid: '',
    isLogin: true,
    remindPopup: false, //提醒弹窗组件
    remind: false, //提醒组件
    kv: {},
    wechatCode: '', //绑定的微信code
    userInfo: {
      snames:[]
    }, //个人信息
    planeImgs: [],
    showPlane: false,
    addPlane: false,
    Bus: null,
  },
  mutations: {
    set(state, { type, data }) {
      state[type] = data;
    },
    setkv(state, data) {
      state.kv[data.k] = data.v;
    },
    removekv(state, data) {
      delete state.kv[data];
    },
    setMobilekv(state, data) {
      //手机版
      state.mobile[data.k] = data.v;
    },
    removeMobilekv(state, data) {
      delete state.mobile[data.k];
    },
  },
  actions: {
    
    login(context, payload) {
      //登录任务管理方法
      const { type, account, password, code } = payload; //ap:账号密码 wx:微信
      if (type === 'ap') {
        let pd = {
          account,
          password,
        };
        if (payload.sms != '') {
          pd.sms = payload.sms;
        }
        return new Promise((resolve, reject) => {
          postLoginUrl(pd)
            .then(res => {
              if (res.data.success) {
                console.log(res.data.data);
                context.commit('set', { type: 'account', data: cookie(document).get('name') }); //保存昵称
                context.commit('set', { type: 'uid', data: res.data.data });
                cookie(document).set('account', payload.account, { path: '/' });
                cookie(document).set('password', res.data.data.password, { path: '/' });
                cookie(document).set('uid', res.data.data.id, { path: '/' });
                postUserInfoLis().then(r => {
                  context.commit('set', { type: 'userInfo', data: r.data.data });
                });
                return res.data;
              } else {
                return res;
              }
            })
            .catch(e => {
              reject(e);
            })
            .then(value => {
              resolve(value);
            });
        });
      } else if (type === 'wx') {
        let fd = new FormData();
        fd.append('code', code);
        return new Promise((resolve, reject) => {
          postWxLogin(fd)
            .then(res => {
              context.commit('set', { type: 'account', data: cookie(document).get('name') }); //保存昵称
              context.commit('set', { type: 'uid', data: res.data.data });
              cookie(document).set('uid', res.data.data.id);
              context.commit('set', { type: 'wechatCode', data: code }); //保存code
              // postUserinfoWxBind({ code }).then(r => {
              //   //绑定微信
              //   console.log('绑定微信', r);
              // });
              postUserInfoLis().then(r => {
                context.commit('set', { type: 'userInfo', data: r.data.data });
              });
              return res.data;
            })
            .catch(e => {
              console.log('******');
              reject(e);
            })
            .then(value => {
              resolve(value);
            });
        });
      }
    },
  },
  modules: {
    login: Login,
    chat: Chat,
    group: Group,
    course: Course,
    mobile: Mobile,
  },
});
