/**
 * Created by Administrator on 2016/12/6 0006.
 */
var exports = (module.exports = function(doc) {
  if (!doc) doc = {};
  if (typeof doc === 'string') doc = { cookie: doc };
  if (doc.cookie === undefined) doc.cookie = '';

  var self = {};
  self.get = function(key) {
    var splat = doc.cookie.split(/;\s*/);
    for (var i = 0; i < splat.length; i++) {
      var ps = splat[i].split('=');
      var k = decodeURI(ps[0]);
      if (k === key) return decodeURI(ps[1]);
    }
    return undefined;
  };

  self.set = function(key, value, opts) {
    if (!opts) opts = {};
    var Days = 2000;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    var s = encodeURI(key) + '=' + encodeURI(value) + '; expires=' + exp.toUTCString();
    if (opts.path) s += '; path=' + escape(opts.path);
    doc.cookie = s;
    return s;
  };
  self.remove = function(key) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = self.get(key);
    if (cval != undefined) doc.cookie = key + '=' + cval + ';expires=' + exp.toUTCString();
  };
  self.clear = function() {
    /*    var keys = doc.cookie.match(/[^ =;]+(?=\=)/g);*/
    var keys = doc.cookie.match(/[^ =;]+(?=)/g);
    if (keys) {
      for (var i = keys.length; i--; ) doc.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
    }
  };
  return self;
});

if (typeof document !== 'undefined') {
  var cookie = exports(document);
  exports.get = cookie.get;
  exports.set = cookie.set;
  exports.remove = cookie.remove;
  exports.clear = cookie.clear;
}
