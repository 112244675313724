import http from './axios';
import { apiUrl } from './env';
/*import qs from 'qs';*/

export const apiGet = (url, params) => {
  return new Promise((resolve, reject) => {
    http.get(apiUrl + url, { params: params }).then(
      response => {
        resolve(response);
      },
      response => {
        reject(response);
        // let st = '';
        // response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
      },
    );
  });
};
export const apiPost = (url, data, config) => {
  if (config === undefined) {
    return new Promise((resolve, reject) => {
      http
        .post(apiUrl + url, data)
        .then(response => {
          resolve(response);
        })
        .catch(response => {
          reject(response);
          // let st = '';
          // response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      http
        .post(apiUrl + url, data, config)
        .then(response => {
          resolve(response);
        })
        .catch(response => {
          reject(response);
          // let st = '';
          // response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
        });
    });
  }
};

export const apiDelete = (url, data) => {
  return new Promise((resolve, reject) => {
    http.delete(apiUrl + url, { data: data }).then(
      response => {
        resolve(response);
      },
      response => {
        reject(response);
      },
    );
  });
};

export const apiPut = (url, data) => {
  return new Promise((resolve, reject) => {
    http
      .put(apiUrl + url, data)
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        reject(response);
        // let st = '';
        // response.response && response.response.status ? st = response.response.status : st = JSON.stringify(response);
      });
  });
};
