import axios from 'axios';
import cookie from '@/utils/cookie';
import { postChatwsMsLis, postChatwsMsInfo, postChatwsMsSend } from '@/service/help';
import { Message } from 'element-ui';

const Chat = {
  state: {
    userList: {
      contactUserList: [],
      groupUserList: [],
      chatroomUserList: [],
      sentMsLis: [], //当表当前聊天列表
    },
    msgList: {
      contact: {},
      group: {},
      chatroom: {},
    },
    currentMsgs: [],

    noticeCallMsg: {},
    chatStatus: '', //聊天状态
  },
  mutations: {
    updateUserList(state, payload) {
      //创建或更新聊天列表
      const { userList, type } = payload;
      let list = []
      userList.forEach(item=>{
        if(Object.hasOwnProperty.call(item,'name')){
          if(item.name){
            list.push(item)
          }
        }
      })
      state.userList[type] = list
    },
    updateMsgList(state, payload) {
      //创建或更新消息列表
      const { chatType, chatId, msg, bySelf, type, mid, isUpdate } = payload;
      let status = 'read';
      if (!state.msgList[chatType][chatId]) {
        //如果该消息列表里该还没这定义，直接赋值
        state.msgList[chatType][chatId] = [
          {
            msg,
            bySelf,
            type: type || '',
            mid: mid,
            status: status,
            ...payload,
          },
        ];
      } else {
        //已经有消息的情况下，用数组push
        if (payload.chatId.indexOf('s-') === -1) {
          state.msgList[chatType][chatId].push({
            msg,
            bySelf,
            type: type || '',
            mid: mid,
            status,
            ...payload,
          });
          state.msgList[chatType][chatId] = state.msgList[chatType][chatId].sort((a, b) => {
            return a.time - b.time;
          });
        } else {
          const len = state.msgList[chatType][chatId].length;
          // 由于全部返回消息，只能过滤掉比当前id要小的
          if (!payload.isUpdate) {
            state.msgList[chatType][chatId].push({
              msg,
              bySelf,
              type: type || '',
              mid: mid,
              status,
              ...payload,
            });
            state.msgList[chatType][chatId] = state.msgList[chatType][chatId].sort((a, b) => {
              return a.time - b.time;
            });
          } else {
            if (state.msgList[chatType][chatId][len - 1].id < payload.id) {
              state.msgList[chatType][chatId].push({
                msg,
                bySelf,
                type: type || '',
                mid: mid,
                status,
                ...payload,
              });
              state.msgList[chatType][chatId] = state.msgList[chatType][chatId].sort((a, b) => {
                return a.time - b.time;
              });
            }
          }
        }
      }
      state.msgList = Object.assign({}, state.msgList); //更新
    },
    updateCurrentMsgList(state, messages) {
      //更新当前消息
      state.currentMsgs = messages;
    },
    updateMessageMid(state, message) {
      const { id, mid } = message;
      /*      const { name } = window.Vue.$route;*/
      let name = 'contact';
      // state.currentMsgs.forEach((item) => {
      //     if(item.mid == id){
      //         item.mid = mid
      //     }
      // })
      Object.keys(state.msgList[name]).forEach(user => {
        if (state.msgList[name][user].length) {
          state.msgList[name][user].forEach(msg => {
            if (msg.mid === id) {
              msg.mid = mid;
            }
          });
        }
      });
    },
    updateMessageStatus(state, message) {
      const { id, mid, action, readUser } = message;
      let name = 'contact';
      Object.keys(state.msgList[name]).forEach(user => {
        if (action === 'oneUserReadMsgs') {
          if (state.msgList[name][readUser]) {
            state.msgList[name][readUser].forEach(msg => {
              if (msg.status !== 'recall') {
                msg.status = 'read';
              }
            });
          }
        } else if (state.msgList[name][user].length) {
          state.msgList[name][user].forEach(msg => {
            if (action === 'readMsgs' && !msg.bySelf) {
              if (msg.status !== 'recall') {
                msg.status = 'read';
              }
            } else if (msg.mid === id || msg.mid === mid) {
              msg.status = message.status;
              if (message.msg) {
                msg.msg = message.msg;
              }
            }
          });
        }
      });
    },
    initChatState(state) {
      state.userList = {
        contactUserList: [],
        groupUserList: [],
        chatroomUserList: [],
      };

      state.msgList = {
        contact: {},
        group: {},
        chatroom: {},
      };

      state.currentMsgs = [];
    },

    // 传递数据给 call 组件，是否收到通话邀请
    noticeCall(state, payload) {
      state.noticeCallMsg = payload;
    },
    updateChatStatus(state, payload) {
      state.chatStatus = payload;
    },
  },
  actions: {
    onGetContactUserList: function(context, payload) {
      // try {
      //   WebIM.conn.getRoster({
      //     success: function(roster) {
      //       // console.log("roster", roster);
      //       const userList = roster.filter(user => ['both', 'to'].includes(user.subscription));
      //       context.commit('updateUserList', {
      //         userList,
      //         type: 'contactUserList',
      //         black: payload,
      //       });
      //     },
      //   });
      // } catch (e) {
      //   console.log('error', e);
      // }
    },
    onGetSendMsLis: function(context, payload) {
      //获取聊天列表
      const { isGetHistory } = payload;
      postChatwsMsLis().then(res => {
        let data = res.data.data;
        context.commit('updateUserList', {
          userList: data,
          type: 'sentMsLis', //自定义类型，代表当前聊天列表
        });
        if (isGetHistory) {
          data.forEach(item => {
            let obj = {};
            obj.chatType = item.is;
            obj.chatId = item.id;
            obj.last_id = 0;
            context.dispatch('getHistoryMessage', obj); //获取历史记录
          });
        }
      });
    },
    onGetChatroomUserList: function(context) {
      // var option = {
      //   pagenum: 1, // 页数
      //   pagesize: 20, // 每页个数
      //   success: function(list) {
      //     context.commit('updateUserList', {
      //       userList: list.data,
      //       type: 'chatroomUserList',
      //     });
      //   },
      //   error: function() {
      //     console.log('List chat room error');
      //   },
      // };
      // WebIM.conn.getChatRooms(option);
    },
    // 获取当前聊天对象的记录 @payload： {key, type}
    onGetCurrentChatObjMsg: function(context, payload) {
      //覆盖
      const { id, type, callback } = payload;
      context.commit('updateCurrentMsgList', context.state.msgList[type][id]);
      callback();
    },
    onSendText: function(context, payload) {},
    sendImgMessage: function(context, payload) {
      //发图片消息
      const { chatType, chatId, file, callback } = payload;
      let userInfo = cookie(document).get('uid');
      let fd = new FormData();
      // fd.append('file', file.data); //数据
      fd.append('uploadfile', file.data); //数据
      axios({
        // url: 'https://a1.easemob.com/1162201207098569/chatdemo/chatfiles',
        url: 'https://up.eai9.com',
        method: 'post',
        data: fd,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
        .then(res => {
          let up = res.data.data;
          postChatwsMsSend({
            target_type: chatType,
            target: [chatId.groupid],
            type: 'img',
            msg: {
              type: 'img',
              filename: file.filename,
              url: up,
            },
            from: userInfo,
          }).then(a => {
            let msgData = a.data.data.others;
            msgData.chatType = chatType === 'chatgroups' ? 'group' : 'contact';
            msgData.bySelf = true;
            msgData.msg = up;
            callback(msgData);
            context.commit('updateMsgList', msgData);
          });
        })
        .catch(e => {
          alert('上传文件过程失败\n' + e + '\n' + '可能暂时不支持上传该格式文件');
        });
    },
    sendFileMessage: function(context, payload) {
      //发文件消息
      const { chatType, chatId, file, callback } = payload;
      let userInfo = cookie(document).get('uid');
      let fd = new FormData();
      // fd.append('file', file.data); //数据
      fd.append('uploadfile', file.data); //数据
      axios({
        // url: 'https://a1.easemob.com/1162201207098569/chatdemo/chatfiles',
        url: 'https://up.eai9.com',
        method: 'post',
        data: fd,
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
        .then(res => {
          let up = res.data.data;
          postChatwsMsSend({
            target_type: chatType,
            target: [chatId.groupid],
            type: 'file',
            msg: {
              type: 'file',
              filename: file.filename,
              file_length: file.data.size,
              url: up,
            },
            from: userInfo,
          }).then(a => {
            let msgData = a.data.data.others;
            msgData.chatType = chatType === 'chatgroups' ? 'group' : 'contact';
            msgData.bySelf = true;
            msgData.msg = up;
            msgData.filename = file.filename;
            msgData.file_length = file.data.size;
            msgData.status = 'sending';
            callback(msgData);
            context.commit('updateMsgList', msgData);
          });
        })
        .catch(e => {
          alert('上传文件过程失败\n' + e + '\n' + '可能暂时不支持上传该格式文件');
        });
    },
    sendRecorder: function(context, payload) {},

    getHistoryMessage: function(context, payload) {
      const { chatId, chatType, last_id, callback, fail, final, isUpdate, canGetLastMsg, getLastMsg } = payload;
      postChatwsMsInfo({
        receive: chatId,
        target_type: chatType,
        last_id: last_id.toString(),
      }).then(res => {
        if (res.data.success) {
          let data = res.data.data;

          if (data.length === 0) return;

          if (chatId.indexOf('s-') === 0) {
            if (context.state.msgList['contact'][chatId]) {
              const len = context.state.msgList['contact'][chatId].length;
              data = data.filter(v => parseInt(v.id) > context.state.msgList['contact'][chatId][len - 1].id);
            }
          }

          if (canGetLastMsg) {
            getLastMsg ? getLastMsg(data) : null;
          }

          for (let k = 0; k < data.length; k++) {
            //格式转化
            let obj = {};
            obj.chatType = data[k].chatType === 'chatgroups' ? 'group' : 'contact';
            obj.chatId = data[k].chatId;
            obj.bySelf = data[k].from === cookie(document).get('uid');
            obj.id = parseInt(data[k].id);
            obj.time = parseInt(data[k].timestamp);
            obj.status = 'reading';
            obj.lis_id = data[k].lis_id;
            let msg = data[k].msg;
            if (msg.type === 'txt') {
              obj.msg = msg.msg;
            } else if (msg.type === 'file') {
              obj.msg = msg.url;
              obj.filename = msg.filename;
              obj.file_length = msg.file_length;
              obj.type = 'file';
            } else if (msg.type === 'img') {
              obj.msg = msg.url;
              obj.type = 'img';
            }
            obj.from = data[k].from;
            obj.user = data[k].user;
            obj.isUpdate = isUpdate ? true : false;
            obj.canGetLastMsg = canGetLastMsg ? true : false;
            //如果不是自己发的话需要转换id
            if (!obj.bySelf && obj.chatType == 'contact') {
              obj.chatId = obj.from;
            }
            context.commit('updateMsgList', obj);
          }
          callback ? callback() : null;
        } else {
          fail ? fail() : null;
        }
        final ? final() : null;
      });
    },

    recallMessage: function(context, payload) {
      // const { chatType, mid } = payload.message;
      // const to = payload.to;
      // /*			const me = this;*/
      // const chatTypeObj = {
      //   contact: 'chat',
      //   group: 'groupchat',
      //   chatroom: 'chatroom',
      // };
      // const option = {
      //   mid,
      //   to,
      //   type: chatTypeObj[chatType],
      //   success: function() {
      //     payload.message.status = 'recall';
      //     payload.message.msg = '消息已撤回';
      //     window.Vue.$store.commit('updateMessageStatus', payload.message);
      //   },
      //   fail: function() {
      //     // me.$message('消息撤回失败');
      //   },
      // };
      // WebIM.conn.recallMessage(option);
    },

    initChatState: function(context) {
      // context.commit('initChatState');
    },
  },

  getters: {
    onGetContactUserList(state) {
      // return state.userList.contactUserList;
    },
    onGetChatroomUserList(state) {
      // return state.userList.chatroomUserList;
    },
    onGetCurrentChatObjMsg(state) {
      // return state.currentMsgs;
    },
    fetchHistoryMessages(state) {
      // return state.currentMsgs;
    },
  },
};
export default Chat;
