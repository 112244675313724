import * as xlsx from 'xlsx';

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};
// 防抖
export function _debounce(fn, delay) {
  delay = delay || 200;
  let timer;
  return function () {
    let th = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      timer = null;
      fn.apply(th, args);
    }, delay);
  };
}
// 节流
export function _throttle(fn, interval) {
  let last;
  let timer;
  interval = interval || 200;
  return function () {
    let th = this;
    let args = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, interval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  };
}

//截取文件后缀
export function suffix(name) {
  //不区分大小写
  return name.match(/\.\w+$/) === null
    ? ''
    : name
        .match(/\.\w+$/)[0]
        .replace('.', '')
        .toLowerCase();
}

//判断文件类型
export function typeFile(fileName) {
  let type =
    fileName.match(/\.\w+$/) === null
      ? ''
      : fileName
          .match(/\.\w+$/)[0]
          .replace('.', '')
          .toLowerCase(); //不区分大小写
  // let type = suffix(fileName);
  let imgArr = ['gif', 'jpg', 'png', 'bmp', 'tiff', 'jfif', 'raw', 'jpeg', 'webp', 'svg', 'psd', 'exif'];
  if (imgArr.includes(type)) {
    return 0;
  } else if (type === 'doc' || type === 'docx' || type === 'wps') {
    return 1;
  } else if (type === 'xlsx' || type === 'xls') {
    return 2;
  } else if (type === 'pptx' || type === 'ppt') {
    return 3;
  } else if (type === 'pdf') {
    return 4;
  } else if (type === 'txt') {
    return 5;
  } else if (type === 'mp3') {
    return 6;
  } else if (
    type === 'mp4' ||
    type === 'avi' ||
    type === 'wmv' ||
    type === 'mpeg' ||
    type === 'realvideo' ||
    type === 'flash' ||
    type === 'mpeg4'
  ) {
    return 7;
  } else if (
    type === 'z' ||
    type === '7z' ||
    type === 'xz' ||
    type === 'zip' ||
    type === 'gz' ||
    type === 'bz2' ||
    type === 'tar'
  ) {
    return 8;
  } else return 999;
}

//将base64转换为blob
export function dataURLtoBlob(dataurl) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

//将blob转换为file
export function blobToFile(theBlob, fileName) {
  theBlob = new File([theBlob], fileName, { type: theBlob.type });
  return theBlob;
}

//返回名字最后两位
export function lastName(name) {
  if (!name) return '';
  return name.charAt(name.length - 2) + name.charAt(name.length - 1);
}

function dc(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      //判断ojb子元素是否为对象，如果是，递归复制
      if (obj[key] && typeof obj[key] === 'object') {
        objClone[key] = dc(obj[key]);
      } else {
        //如果不是，简单复制
        objClone[key] = obj[key];
      }
    }
  }
  return objClone;
}

export const deepClone = dc;

//xslx
export const XLSX = xlsx;

export const openDownload = function (blob, fileName) {
  if (typeof blob === 'object' && blob instanceof Blob) {
    blob = URL.createObjectURL(blob); // 创建blob地址
  }
  const aLink = document.createElement('a');
  aLink.href = blob;
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，有时候 file:///模式下不会生效
  aLink.download = fileName || '';
  let event;
  if (window.MouseEvent) event = new MouseEvent('click');
  //   移动端
  else {
    event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  }
  aLink.dispatchEvent(event);
};
export const workbook2blob = function (workbook) {
  // 生成excel的配置项
  const wopts = {
    // 要生成的文件类型
    bookType: 'xlsx',
    // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: 'binary',
  };
  const wbout = xlsx.write(workbook, wopts);
  // 将字符串转ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  const blob = new Blob([s2ab(wbout)], {
    type: 'application/octet-stream',
  });
  return blob;
};
let format = value => {
  return value >= 10 ? value : '0' + value;
};
export const dateFormat = function (type = '', date = new Date()) {
  let year = date.getFullYear(),
    month = date.getMonth() + 1,
    day = date.getDate(),
    hours = date.getHours(),
    minutes = date.getMinutes(),
    second = date.getSeconds(),
    result = '';
  switch (type) {
    case 'YY': // 2016
      result = `${year}`;
      break;
    case 'MM': // 01
      result = `${format(month)}`;
      break;
    case 'DD': // 05
      result = `${format(day)}`;
      break;
    case 'YY-MM': // 2016-02
      result = `${year}-${format(month)}`;
      break;
    case 'MM-DD': // 01-05
      result = `${format(month)}-${format(day)}`;
      break;
    case 'hh:mm': // 11:12
      result = `${format(hours)}:${format(minutes)}`;
      break;
    case 'YY-MM-DD': // 2015-01-05
      result = `${year}-${format(month)}-${format(day)}`;
      break;
    case 'YY-MM-DD hh:mm': // 2015-01-05 11:12
      result = `${year}-${format(month)}-${format(day)}  ${format(hours)}:${format(minutes)}`;
      break;
    case 'YY-MM-DD hh:mm:ss': // 2015-01-05 11:12:06
      result = `${year}-${format(month)}-${format(day)}  ${format(hours)}:${format(minutes)}:${format(second)}`;
      break;
    case 'YY年MM月DD日 hh:mm': // 2015年01月05日 11:12
      result = `${year}年${format(month)}月${format(day)}日  ${format(hours)}:${format(minutes)}`;
      break;
    default:
      result = date;
      break;
  }
  return result;
};

export const dealWithTime = function (data) {
  //时间
  let formatDateTime;
  let M = data.getMonth() + 1;
  let D = data.getDate();
  let W = data.getDay();
  switch (
    W //转换星期
  ) {
    case 0:
      W = '天';
      break;
    case 1:
      W = '一';
      break;
    case 2:
      W = '二';
      break;
    case 3:
      W = '三';
      break;
    case 4:
      W = '四';
      break;
    case 5:
      W = '五';
      break;
    case 6:
      W = '六';
      break;
    default:
      break;
  }
  formatDateTime = M + '月' + D + '日 ' + ' 星期' + W;
  return formatDateTime;
};

// 比较两个数组，获得差值
export const differenceArr = (oldData,newData)=>{
  // 去重
  let arr1 = new Set(oldData)
  let arr2 = new Set(newData)
  let list = []
  for(let k of arr2){
    if(!arr1.has(k)){
      list.push(k)
    }
  }
  return list
}

export const checkEndDate = (st, et) => {
  return new Date(st).getTime() > new Date(et).getTime()
}

export const phoneReg =
  /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/