import Vue from 'vue';
import router from './router';
import store from './store';
import _isMobile from './_isMobile';
import './common/font/font.css';
import './assets/style/iconfont.css';
import socket from '@/utils/socket';


import './vant';
import VueTypedJs from 'vue-typed-js'
import highlightjs from "./utils/vue-plugin-1.0.2/vue.js";
import 'highlight.js/styles/vs2015.css'; // 引入内置样式
import 'highlight.js/styles/atom-one-dark.css';
Vue.use(highlightjs);

Vue.use(VueTypedJs)

import { lastName } from '@/utils/utils';
Vue.prototype.lastName = lastName;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import draggable from 'vuedraggable'
Vue.use(draggable)




// let _isMobile = () => {
//   let flag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
//   );
//   return flag;
// };

// 将属性挂载在vue上 默认线上 online   查询排序网址 qcc 
Vue.prototype.VUE_APP_CONFIG = process.env.VUE_APP_CONFIG || 'online'  

window.throwErrorTips = (title,msg)=>{
  let message = '';
  title ? message = title+'，ERROR：'+msg : message = 'ERROR：'+msg;
  Vue.prototype.$message({
      showClose: true,
      message: message,
      type: 'error'
  });
}

window.throwSuccessTips = (msg)=>{
  Vue.prototype.$message({
      showClose: true,
      message: msg,
      type: 'success'
  });
}


let App = _isMobile()
  ? async () => {
      await import('./mobile');
      Vue.prototype.switchFoot = function (data) {
        store.commit('setMobilekv', {
          k: 'switchFoot',
          v: data,
        });
      };
      return import('./Mobile.vue');
    }
  : async () => {
      import('./pc');
      return import('./App.vue');
    };
localStorage.setItem('isMobie', !!_isMobile());
window.Vue = new Vue({
  router,
  store,
  render: h => h(App),
  socket,
}).$mount('#app');
